import {Dispatch, FC, SetStateAction} from 'react';
import styles from './eye-box.module.scss';
import i18n from '../../../translations/i18n';

import {ReactComponent as RectangleIcon} from '../../../assets/icons/eyes/rectangle.svg';
import {ReactComponent as Rectangle2Icon} from '../../../assets/icons/eyes/rectangle2.svg';
import {ReactComponent as CircleIcon} from '../../../assets/icons/eyes/circle.svg';
import {ReactComponent as HalfRoundedIcon} from '../../../assets/icons/eyes/half-rounded.svg';
import {ReactComponent as RoundedRectangleIcon} from '../../../assets/icons/eyes/rounded-rectangle.svg';
import {ReactComponent as RectangleMarkerIcon} from '../../../assets/icons/eyes/rectangle-marker.svg';
import {ReactComponent as Rectangle2MarkerIcon} from '../../../assets/icons/eyes/rectangle2-marker.svg';
import {ReactComponent as CircleMarkerIcon} from '../../../assets/icons/eyes/circle-marker.svg';
import {ReactComponent as HalfRoundedMarkerIcon} from '../../../assets/icons/eyes/half-rounded-marker.svg';
import {ReactComponent as RoundedRectangleMarkerIcon} from '../../../assets/icons/eyes/rounded-rectangle-marker.svg';
import {ReactComponent as StarMarkerIcon} from '../../../assets/icons/eyes/star-marker.svg';
import {ReactComponent as HeartMarkerIcon} from '../../../assets/icons/eyes/heart-marker.svg';
import {rectangleBase64Image} from '../../../assets/icons/base64/eyes/rectangle';
import {circleBase64Image} from '../../../assets/icons/base64/eyes/circle';
import {halfRoundedBase64Image} from '../../../assets/icons/base64/eyes/half-rounded';
import {roundedRectangleBase64Image} from '../../../assets/icons/base64/eyes/rounded-rectangle';
import {circleMarkerBase64Image} from '../../../assets/icons/base64/eyes/circle-marker';
import {roundedRectangleMarkerBase64Image} from '../../../assets/icons/base64/eyes/rounded-rectangle-marker';
import {starMarkerBase64Image} from '../../../assets/icons/base64/eyes/star-marker';
import {heartMarkerBase64Image} from '../../../assets/icons/base64/eyes/heart-marker';
import {halfRoundedMarkerBase64Image} from '../../../assets/icons/base64/eyes/half-rounded-marker';
import {rectangleMarkerBase64Image} from '../../../assets/icons/base64/eyes/rectangle-marker';
import {rectangle2Base64Image} from '../../../assets/icons/base64/eyes/rectangle2';
import {rectangle2MarkerBase64Image} from '../../../assets/icons/base64/eyes/rectangle2-marker';



interface EyeBoxProps {
    setQrEye: Dispatch<SetStateAction<string>>;
    setQrEyeMarker: Dispatch<SetStateAction<string>>;
}

const EyeBox: FC<EyeBoxProps> = ({
    setQrEye, setQrEyeMarker
}) => {

    return (
        <div className={styles.eyeContainer}>
            <div className={styles.eyeWrapper}>
                {i18n.t('common.eyeBorder')}
                <div className={styles.eyeIcons}>
                    <div className={styles.logoWrapper} onClick={() => setQrEye(rectangleBase64Image)}>
                        <RectangleIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEye(rectangle2Base64Image)}>
                        <Rectangle2Icon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEye(circleBase64Image)}>
                        <CircleIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEye(halfRoundedBase64Image)}>
                        <HalfRoundedIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEye(roundedRectangleBase64Image)}>
                        <RoundedRectangleIcon className={styles.logo}/>
                    </div>
                </div>
            </div>
            <div className={styles.eyeWrapper}>
                {i18n.t('common.eyeCenter')}
                <div className={styles.eyeIcons}>
                    <div className={styles.logoWrapper} onClick={() => setQrEyeMarker(rectangleMarkerBase64Image)}>
                        <RectangleMarkerIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEyeMarker(rectangle2MarkerBase64Image)}>
                        <Rectangle2MarkerIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEyeMarker(circleMarkerBase64Image)}>
                        <CircleMarkerIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEyeMarker(halfRoundedMarkerBase64Image)}>
                        <HalfRoundedMarkerIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEyeMarker(roundedRectangleMarkerBase64Image)}>
                        <RoundedRectangleMarkerIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEyeMarker(starMarkerBase64Image)}>
                        <StarMarkerIcon className={styles.logo}/>
                    </div>
                    <div className={styles.logoWrapper} onClick={() => setQrEyeMarker(heartMarkerBase64Image)}>
                        <HeartMarkerIcon className={styles.logo}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EyeBox;