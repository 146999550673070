import {FC, ReactNode, createContext, Dispatch, SetStateAction, useContext} from 'react';
import {QR_CODE_OPTION_TEXT, QRCodeOptions} from '../types/qrcode';
import {WIFI_ENCYRPT_TYPE_WEP, WIFI_ENCYRPT_TYPE_WPA} from '../constants/common';

export interface QrCodeDataPhone {
    code: string,
    phone: string
}

export interface QrCodeDataEmail {
    email: string,
    subject: string,
    message: string
}

export interface QrCodeDataSms {
    code: string,
    phone: string,
    message: string
}

export type WifiEncryptType = typeof WIFI_ENCYRPT_TYPE_WEP | typeof WIFI_ENCYRPT_TYPE_WPA;

export interface QrCodeDataWifi {
    name: string,
    type: WifiEncryptType,
    password: string,
    hidden: boolean
}

export const qrCodeDataPhoneInitial = {
    code: '',
    phone: ''
};

export const qrCodeDataEmailInitial = {
    email: '',
    subject: '',
    message: ''
};

export const qrCodeDataSmsInitial = {
    code: '',
    phone: '',
    message: ''
};

export const qrCodeDataWifiInitial = {
    name: '',
    type: WIFI_ENCYRPT_TYPE_WPA as WifiEncryptType,
    password: '',
    hidden: false
};

interface QrCodeDataContextProps {
    selected: QRCodeOptions,
    setSelected: Dispatch<SetStateAction<QRCodeOptions>>,
    text: string,
    setText: Dispatch<SetStateAction<string>>,
    link: string,
    setLink: Dispatch<SetStateAction<string>>,
    phone: QrCodeDataPhone,
    setPhone: Dispatch<SetStateAction<QrCodeDataPhone>>,
    email: QrCodeDataEmail,
    setEmail: Dispatch<SetStateAction<QrCodeDataEmail>>,
    sms: QrCodeDataSms,
    setSms: Dispatch<SetStateAction<QrCodeDataSms>>,
    wifi: QrCodeDataWifi,
    setWifi: Dispatch<SetStateAction<QrCodeDataWifi>>,
}

const QrCodeDataContext = createContext<QrCodeDataContextProps>({
    selected: QR_CODE_OPTION_TEXT,
    setSelected: () => {},
    text: '',
    setText: () => {},
    link: '',
    setLink: () => {},
    phone: qrCodeDataPhoneInitial,
    setPhone: () => {},
    email: qrCodeDataEmailInitial,
    setEmail: () => {},
    sms: qrCodeDataSmsInitial,
    setSms: () => {},
    wifi: qrCodeDataWifiInitial,
    setWifi: () => {},
});

interface QrCodeDataContextProviderProps extends QrCodeDataContextProps {
    children: ReactNode;
}

export const QrCodeDataProvider: FC<QrCodeDataContextProviderProps> = ({
    children, selected, setSelected, text, setText, link, setLink, phone, setPhone, email, setEmail, sms, setSms, wifi, setWifi
}) => {
    return (
        <QrCodeDataContext.Provider
            value={{
                selected: selected,
                setSelected: setSelected,
                text: text,
                setText: setText,
                link: link,
                setLink: setLink,
                phone: phone,
                setPhone: setPhone,
                email: email,
                setEmail: setEmail,
                sms: sms,
                setSms: setSms,
                wifi: wifi,
                setWifi: setWifi
            }}
        >
            { children }
        </QrCodeDataContext.Provider>
    );
};

export default QrCodeDataContext;

export const useQrCodeDataContext = () => useContext(QrCodeDataContext);