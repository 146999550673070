import React from 'react';
import styles from './qr-code-data-options.module.scss';
import tStyles from '../../assets/css/text.module.scss';
import {Input} from '../input/Input';
import i18n from '../../translations/i18n';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {TextArea} from '../textArea/TextArea';
import {useQrCodeDataContext} from '../../context/QrCodeDataProvider';

const QrCodeSmsOption  = () => {
    const {sms, setSms} = useQrCodeDataContext();

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => setSms(prevState => ({ ...prevState, phone: event.target.value }));
    const handleChangeCode = (value: string) => setSms(prevState => ({ ...prevState, code: value }));
    const handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => setSms(prevState => ({ ...prevState, message: event.target.value }));

    return (
        <div className={styles.container}>
            <div className={styles.phoneWrapper}>
                <div className={styles.countryCodeWrapper}>
                    <div className={tStyles.bigText}>
                        {i18n.t('common.countryCode')}
                    </div>
                    <PhoneInput
                        value={sms.code}
                        onChange={handleChangeCode}
                        enableSearch={true}
                        disableInitialCountryGuess={false}
                        inputClass={styles.phoneInput}
                        dropdownClass={styles.phoneDropdown}
                        containerClass={styles.phoneContainer}
                        placeholder={`${i18n.t('common.select')}...`}
                    />
                </div>
                <Input
                    label={i18n.t('common.phoneNumber')}
                    value={sms.phone}
                    onChange={handleChangePhone}
                    placeholder={i18n.t('common.phoneNumber')}
                />
            </div>
            <TextArea
                value={sms.message}
                label={i18n.t('common.message')}
                onChange={handleChangeMessage}
                placeholder={'...'}
                rows={4}
                maxLength={250}
            />
        </div>
    );
};

export default QrCodeSmsOption;