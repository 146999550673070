import React from 'react';
import styles from './qr-code-data-options.module.scss';
import i18n from '../../translations/i18n';
import {Input} from '../input/Input';
import {useQrCodeDataContext} from '../../context/QrCodeDataProvider';


const QrCodeLinkOptions = () => {
    const {link, setLink} = useQrCodeDataContext();

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => setLink(event.target.value);

    return (
        <div className={styles.container}>
            <Input
                label={i18n.t('common.enterUrl')}
                value={link}
                onChange={handleChangeValue}
                placeholder={i18n.t('common.https')}
            />
        </div>
    );
};

export default QrCodeLinkOptions;