import {Dispatch, FC, SetStateAction, useState} from 'react';
import {SketchPicker} from 'react-color';
import {Input} from '../../input/Input';
import styles from './color.module.scss';
import i18n from '../../../translations/i18n';


interface ColorBoxProps {
    bgColor: string,
    setBgColor: Dispatch<SetStateAction<string>>,
    dotsColor: string,
    setDotsColor: Dispatch<SetStateAction<string>>,
    eyeMarkerColor: string,
    setEyeMarkerColor: Dispatch<SetStateAction<string>>,
    eyeBorderColor: string,
    setEyeBorderColor: Dispatch<SetStateAction<string>>
}

const ColorBox: FC<ColorBoxProps> = ({
    bgColor, setBgColor, dotsColor, setDotsColor, eyeMarkerColor, setEyeMarkerColor, eyeBorderColor, setEyeBorderColor
}) => {
    const [showBgColorPicker, setShowBgColorPicker] = useState<boolean>(false);
    const [showDotsColorPicker, setShowDotsColorPicker] = useState<boolean>(false);
    const [showEyeMarkerColorPicker, setShowEyeMarkerColorPicker] = useState<boolean>(false);
    const [showEyeBorderColorPicker, setShowEyeBorderColorPicker] = useState<boolean>(false);

    const handleChangeBgColor = (color: any) => setBgColor(color.hex.toUpperCase());
    const handleChangeDotsColor = (color: any) => setDotsColor(color.hex.toUpperCase());
    const handleChangeEyeMarkerColor = (color: any) => setEyeMarkerColor(color.hex.toUpperCase());
    const handleChangeEyeBorderColor = (color: any) => setEyeBorderColor(color.hex.toUpperCase());

    const hideShowBgColorPicker = () => setShowBgColorPicker(prevState => !prevState);
    const hideShowDotsColorPicker = () => setShowDotsColorPicker(prevState => !prevState);
    const hideShowEyeMarkerColorPicker = () => setShowEyeMarkerColorPicker(prevState => !prevState);
    const hideShowEyeBorderColorPicker = () => setShowEyeBorderColorPicker(prevState => !prevState);

    return (
        <div className={styles.colorContainer}>
            <div className={styles.colorOption}>
                <Input label={i18n.t('common.bgColor')} value={bgColor}/>
                <div className={styles.colorWrapper} onClick={hideShowBgColorPicker} style={{backgroundColor: bgColor}}>
                </div>
                {showBgColorPicker &&
                    <SketchPicker
                        color={bgColor}
                        onChange={handleChangeBgColor}
                        className={styles.sketchPicker}
                    />
                }
            </div>
            <div className={styles.colorOption}>
                <Input label={i18n.t('common.dotsColor')} value={dotsColor}/>
                <div className={styles.colorWrapper} onClick={hideShowDotsColorPicker}
                    style={{backgroundColor: dotsColor}}>
                </div>
                {showDotsColorPicker &&
                    <SketchPicker
                        color={dotsColor}
                        onChange={handleChangeDotsColor}
                        className={styles.sketchPicker}
                    />
                }
            </div>
            <div className={styles.colorOption}>
                <Input label={i18n.t('common.eyeBorderColor')} value={eyeBorderColor}/>
                <div className={styles.colorWrapper} onClick={hideShowEyeBorderColorPicker} style={{backgroundColor: eyeBorderColor}}>
                </div>
                {showEyeBorderColorPicker &&
                    <SketchPicker
                        color={eyeBorderColor}
                        onChange={handleChangeEyeBorderColor}
                        className={styles.sketchPicker}
                    />
                }
            </div>
            <div className={styles.colorOption}>
                <Input label={i18n.t('common.eyeCenterColor')} value={eyeMarkerColor}/>
                <div className={styles.colorWrapper} onClick={hideShowEyeMarkerColorPicker} style={{backgroundColor: eyeMarkerColor}}>
                </div>
                {showEyeMarkerColorPicker &&
                    <SketchPicker
                        color={eyeMarkerColor}
                        onChange={handleChangeEyeMarkerColor}
                        className={styles.sketchPicker}
                    />
                }
            </div>
        </div>
    );
};

export default ColorBox;