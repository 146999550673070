import React from 'react';
import styles from './qr-code-data-options.module.scss';
import tStyles from '../../assets/css/text.module.scss';
import {Input} from '../input/Input';
import i18n from '../../translations/i18n';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useQrCodeDataContext} from '../../context/QrCodeDataProvider';

const QrCodePhoneOption  = () => {
    const {phone, setPhone} = useQrCodeDataContext();

    const handleChangeCountryCode = (code: string) => setPhone(prevState => ({ ...prevState, code: code }));

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => (
        setPhone(prevState => ({ ...prevState, phone: event.target.value }))
    );

    return (
        <div className={styles.container}>
            <div className={styles.phoneWrapper}>
                <div className={styles.countryCodeWrapper}>
                    <div className={tStyles.bigText}>
                        {i18n.t('common.countryCode')}
                    </div>
                    <PhoneInput
                        value={phone.code}
                        onChange={handleChangeCountryCode}
                        enableSearch={true}
                        disableInitialCountryGuess={false}
                        inputClass={styles.phoneInput}
                        dropdownClass={styles.phoneDropdown}
                        containerClass={styles.phoneContainer}
                        placeholder={`${i18n.t('common.select')}...`}
                    />
                </div>
                <Input
                    label={i18n.t('common.phoneNumber')}
                    value={phone.phone}
                    onChange={handleChangePhone}
                    placeholder={i18n.t('common.phoneNumber')}
                />
            </div>
        </div>
    );
};

export default QrCodePhoneOption;