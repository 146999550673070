import * as React from 'react';
import {ElementType, FC, useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styles from './selector.module.scss';

interface SelectorProps {
    label?: string,
    items: string[],
    onSelect: (value: string) => void,
    defaultValue?: string,
    icon?: ElementType
    MenuProps?: object;
    formStyle?: string,
    selectStyle?: object;
    selectedItemStyle?: React.CSSProperties;
}

export const Selector: FC<SelectorProps> = ({
    label, items, onSelect, defaultValue, icon, MenuProps,
    formStyle, selectStyle, selectedItemStyle,
}) => {
    const [value, setValue] = React.useState(defaultValue ?? '');

    const handleChange = (event: SelectChangeEvent) => {
        const val = event.target.value;
        setValue(val);
        onSelect(val);
    };

    useEffect(() => {
        (defaultValue && defaultValue != value) && setValue(defaultValue);
    }, [defaultValue]);

    return (
        <FormControl id='selector-form' className={`${styles.form} ${formStyle}`} size="small">
            <InputLabel id="label" className={styles.label}>
                {label}
            </InputLabel>
            <Select
                className={styles.select}
                labelId="label"
                id="select"
                value={value}
                label={label}
                onChange={handleChange}
                IconComponent={icon}
                sx={selectStyle}
                MenuProps={MenuProps}
            >
                {items.map(item => (
                    <MenuItem 
                        value={item} 
                        style={value === item ? selectedItemStyle : {}}
                        key={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};