import React, {ChangeEvent} from 'react';
import styles from './qr-code-data-options.module.scss';
import {Input} from '../input/Input';
import i18n from '../../translations/i18n';
import {useQrCodeDataContext, WifiEncryptType} from '../../context/QrCodeDataProvider';
import {Switch} from '@mui/material';
import {Selector} from '../selector/Selector';
import {WIFI_ENCRYPT_ITEMS} from '../../constants/common';

const QrCodeWifiOption  = () => {
    const {wifi, setWifi} = useQrCodeDataContext();

    const handleChangeNetworkName = (event: ChangeEvent<HTMLInputElement>) => setWifi(prevState => ({ ...prevState, name: event.target.value }));
    const handleChangeNetworkType = (value: string) => setWifi(prevState => ({ ...prevState, type: value as WifiEncryptType }));
    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => setWifi(prevState => ({ ...prevState, password: event.target.value }));

    const handleHidden = () => setWifi(prevState => ({ ...prevState, hidden: !prevState.hidden }));

    return (
        <div className={styles.container}>
            <Input
                value={wifi.name}
                label={i18n.t('common.networkName')}
                onChange={handleChangeNetworkName}
                placeholder={'...'}
            />
            <Selector
                defaultValue={wifi.type}
                label={i18n.t('common.networkType')}
                onSelect={handleChangeNetworkType}
                items={WIFI_ENCRYPT_ITEMS}
            />
            <Input
                value={wifi.password}
                label={i18n.t('common.password')}
                onChange={handleChangePassword}
                placeholder={'...'}
            />
            <div className={styles.normalText}>
                <Switch defaultChecked={false} onChange={handleHidden}/>
                {i18n.t('common.hideNetwork')}
            </div>
        </div>
    );
};

export default QrCodeWifiOption;