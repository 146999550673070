import styles from './qr-code-data-options.module.scss';
import tStyles from '../../assets/css/text.module.scss';
import {ReactComponent as TextIcon} from '../../assets/icons/options/text.svg';
import {ReactComponent as EmailIcon} from '../../assets/icons/options/email.svg';
import {ReactComponent as LinkIcon} from '../../assets/icons/options/link.svg';
import {ReactComponent as PhoneIcon} from '../../assets/icons/options/phone.svg';
import {ReactComponent as SmsIcon} from '../../assets/icons/options/sms.svg';
import {ReactComponent as WifiIcon} from '../../assets/icons/wifi.svg';
import i18n from '../../translations/i18n';
import QrCodeLinkOption from './QrCodeLinkOption';
import {
    QR_CODE_OPTION_EMAIL,
    QR_CODE_OPTION_LINK,
    QR_CODE_OPTION_PHONE,
    QR_CODE_OPTION_SMS,
    QR_CODE_OPTION_TEXT, QR_CODE_OPTION_WIFI
} from '../../types/qrcode';
import QrCodeEmailOptions from './QrCodeEmailOptions';
import QrCodeTextOption from './QrCodeTextOption';
import QrCodePhoneOption from './QrCodePhoneOption';
import QrCodeSmsOption from './QrCodeSmsOption';
import {useQrCodeDataContext} from '../../context/QrCodeDataProvider';
import QrCodeWifiOption from './QrCodeWifiOption';

const QrCodeDataOptions = () => {
    const { selected, setSelected} = useQrCodeDataContext();

    const isText = selected === QR_CODE_OPTION_TEXT;
    const isEmail = selected === QR_CODE_OPTION_EMAIL;
    const isLink = selected === QR_CODE_OPTION_LINK;
    const isPhone = selected === QR_CODE_OPTION_PHONE;
    const isSms = selected === QR_CODE_OPTION_SMS;
    const isWifi = selected === QR_CODE_OPTION_WIFI;

    return (
        <div className={styles.container}>
            <div className={styles.options}>
                <div
                    className={`${styles.iconBox} ${isLink && styles.selected}`}
                    onClick={() => setSelected(QR_CODE_OPTION_LINK)}
                >
                    <LinkIcon className={styles.icon}/>
                    <div className={tStyles.normalText}>
                        {i18n.t('common.link')}
                    </div>
                </div>
                <div
                    className={`${styles.iconBox} ${isText && styles.selected}`}
                    onClick={() => setSelected(QR_CODE_OPTION_TEXT)}
                >
                    <TextIcon className={styles.icon}/>
                    <div className={tStyles.normalText}>
                        {i18n.t('common.text')}
                    </div>
                </div>
                <div
                    className={`${styles.iconBox} ${isEmail && styles.selected}`}
                    onClick={() => setSelected(QR_CODE_OPTION_EMAIL)}
                >
                    <EmailIcon className={styles.icon}/>
                    <div className={tStyles.normalText}>
                        {i18n.t('common.email')}
                    </div>
                </div>
                <div
                    className={`${styles.iconBox} ${isPhone && styles.selected}`}
                    onClick={() => setSelected(QR_CODE_OPTION_PHONE)}
                >
                    <PhoneIcon className={styles.icon}/>
                    <div className={tStyles.normalText}>
                        {i18n.t('common.phone')}
                    </div>
                </div>
                <div
                    className={`${styles.iconBox} ${isSms && styles.selected}`}
                    onClick={() => setSelected(QR_CODE_OPTION_SMS)}
                >
                    <SmsIcon className={styles.icon}/>
                    <div className={tStyles.normalText}>
                        {i18n.t('common.sms')}
                    </div>
                </div>
                <div
                    className={`${styles.iconBox} ${isWifi && styles.selected}`}
                    onClick={() => setSelected(QR_CODE_OPTION_WIFI)}
                >
                    <WifiIcon className={styles.icon}/>
                    <div className={tStyles.normalText}>
                        {i18n.t('common.wifi')}
                    </div>
                </div>
            </div>
            <div>
                {isLink && <QrCodeLinkOption/>}
                {isText && <QrCodeTextOption/>}
                {isEmail && <QrCodeEmailOptions/>}
                {isPhone && <QrCodePhoneOption/>}
                {isSms && <QrCodeSmsOption/>}
                {isWifi && <QrCodeWifiOption/>}
            </div>
        </div>
    );
};

export default QrCodeDataOptions;