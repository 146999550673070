import React from 'react';
import styles from './qr-code-data-options.module.scss';
import {Input} from '../input/Input';
import i18n from '../../translations/i18n';
import {TextArea} from '../textArea/TextArea';
import {useQrCodeDataContext} from '../../context/QrCodeDataProvider';

const QrCodeEmailOptions  = () => {
    const {email, setEmail} = useQrCodeDataContext();

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(prevState => ({ ...prevState, email: event.target.value }));
    const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(prevState => ({ ...prevState, subject: event.target.value }));
    const handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(prevState => ({ ...prevState, message: event.target.value }));

    return (
        <div className={styles.container}>
            <Input
                value={email.email}
                label={i18n.t('common.yourEmailAddress')}
                onChange={handleChangeEmail}
                placeholder={'...'}
            />
            <Input
                value={email.subject}
                label={i18n.t('common.subject')}
                onChange={handleChangeSubject}
                placeholder={'...'}
            />
            <TextArea
                value={email.message}
                label={i18n.t('common.message')}
                onChange={handleChangeMessage}
                placeholder={'...'}
                rows={4}
                maxLength={250}
            />
        </div>
    );
};

export default QrCodeEmailOptions;