export const ENGLISH = {
    cookie: {
        text: 'We use cookies to understand how you use our site and to improve your experience. By continuing, you accept our cookie policy.'
    },
    common: {
        active: 'Active',
        big: 'Big',
        bgColor: 'Background color',
        colors: 'Colors',
        content: 'Content',
        cornerEyes: 'Corner eyes',
        countryCode: 'Country code',
        createQrCode: 'CREATE QR CODE',
        dotsColor: 'Dots color',
        download: 'Download',
        editQrContent: 'Edit QR-Content',
        enterUrl: 'Enter URL',
        eyeBorder: 'Eye border',
        eyeBorderColor: 'Eye border color',
        eyeCenter: 'Eye center',
        eyeCenterColor: 'Eye center color',
        email: 'E-mail',
        enterQrCodeName: 'Enter QR Code name',
        frames: 'Frames',
        hideNetwork: 'Hide network',
        https: 'https://',
        inactive: 'Inactive',
        link: 'Link',
        logo: 'Logo',
        medium: 'Medium',
        message: 'Message',
        myQrCode: 'MyQRCode',
        networkName: 'Network name',
        networkType: 'Network type',
        password: 'Password',
        phone: 'Phone',
        phoneNumber: 'Phone number',
        save: 'Save',
        scans: 'Scans',
        scanDetails: 'Scan details',
        select: 'Select',
        shapes: 'Shapes',
        signup: 'SIGN UP',
        signOut: 'Sign out',
        small: 'Small',
        sms: 'SMS',
        subject: 'Subject',
        text: 'Text',
        uploadImage: 'Upload image',
        uploadLogo: 'Upload logo',
        wifi: 'WI-FI',
        yourEmailAddress: 'Your email address'
    },
    footer: {
        privacyPolicy: 'Privacy policy',
        termsAndConditions: 'Terms and Conditions',
    },
    header: {
        title: 'FREE QR Code Styling & Generator',
        subtitle: 'The platform with the most qrcode customization options'
    },
    logo: {
        removeDotsLogo: 'Remove dots behind Logo',
    },
    validation: {
        fileTooLarge: 'File is too large. Please upload a file smaller than 5MB.',
        invalidFileType: 'Invalid file type. Please upload a PNG or JPEG file.',
    },
    qrCode: {
        faq: 'What is QR code?',
        desc: 'A QR code (Quick Response code) is a two-dimensional barcode consisting of black squares arranged on a white grid, which stores encoded data such as text, URLs, contact information, or other types of information. It can be easily scanned and interpreted by smartphones, tablets, or dedicated QR code readers using their cameras. Unlike traditional barcodes that only store data in one direction (horizontally), QR codes store information both horizontally and vertically, allowing them to hold significantly more data. QR codes are commonly used for quickly directing users to websites, sharing Wi-Fi credentials, making payments, or providing access to digital content, and they can be customized in size and design while still remaining scannable.'
    }
};