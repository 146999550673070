import React from 'react';
import styles from './qr-code-data-options.module.scss';
import i18n from '../../translations/i18n';
import {TextArea} from '../textArea/TextArea';
import {useQrCodeDataContext} from '../../context/QrCodeDataProvider';

const QrCodeTextOptions  = () => {
    const {text, setText} = useQrCodeDataContext();

    const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => setText(event.target.value);

    return (
        <div className={styles.container}>
            <TextArea
                value={text}
                label={i18n.t('common.text')}
                onChange={handleChangeText}
                placeholder={'...'}
                rows={4}
                maxLength={250}
            />
        </div>
    );
};

export default QrCodeTextOptions;