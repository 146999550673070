import {useState} from 'react';
import styles from './qr-code-container.module.scss';
import QrCodeDataOptions from '../../components/qrCodeDataOptions/QrCodeDataOptions';
import QrCodeCreatorBox from '../../components/qrCodeCreatorBox/QrCodeCreatorBox';
import {
    QR_CODE_OPTION_EMAIL,
    QR_CODE_OPTION_LINK,
    QR_CODE_OPTION_PHONE,
    QR_CODE_OPTION_SMS,
    QR_CODE_OPTION_TEXT, QR_CODE_OPTION_WIFI,
    QRCodeOptions
} from '../../types/qrcode';
import {
    QrCodeDataEmail, qrCodeDataEmailInitial,
    QrCodeDataPhone,
    qrCodeDataPhoneInitial,
    QrCodeDataProvider,
    QrCodeDataSms, qrCodeDataSmsInitial, QrCodeDataWifi, qrCodeDataWifiInitial
} from '../../context/QrCodeDataProvider';

const QrCodeContainer = () => {
    const [text, setText] = useState<string>('');
    const [link, setLink] = useState<string>('');
    const [phone, setPhone] = useState<QrCodeDataPhone>(qrCodeDataPhoneInitial);
    const [email, setEmail] = useState<QrCodeDataEmail>(qrCodeDataEmailInitial);
    const [sms, setSms] = useState<QrCodeDataSms>(qrCodeDataSmsInitial);
    const [wifi, setWifi] = useState<QrCodeDataWifi>(qrCodeDataWifiInitial);
    const [selected, setSelected] = useState<QRCodeOptions>(QR_CODE_OPTION_TEXT);

    const getQrCodeData = (): string => {
        if (selected === QR_CODE_OPTION_TEXT) return text;
        if (selected === QR_CODE_OPTION_LINK) return link;
        if (selected === QR_CODE_OPTION_PHONE) return `tel:+${phone.code}${phone.phone}`;
        if (selected === QR_CODE_OPTION_SMS) return `sms:+${sms.code}${sms.phone}?body=${sms.message}`;
        if (selected === QR_CODE_OPTION_EMAIL) return `mailto:${email.email}?subject=${email.subject}&body=${email.message}`;
        if (selected === QR_CODE_OPTION_WIFI) return `WIFI:S:${wifi.name};T:${wifi.type};P:${wifi.password};H:${wifi.hidden ? 'true' : 'false'};;`;
        return '';
    };

    const qrCodeData = getQrCodeData();

    return (
        <QrCodeDataProvider
            selected={selected}
            setSelected={setSelected}
            text={text}
            setText={setText}
            link={link}
            setLink={setLink}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            sms={sms}
            setSms={setSms}
            wifi={wifi}
            setWifi={setWifi}
        >
            <div className={styles.qrcodeBox}>
                <QrCodeDataOptions />
                <QrCodeCreatorBox
                    qrCodeData={qrCodeData}
                />
            </div>
        </QrCodeDataProvider>
    );
};

export default QrCodeContainer;