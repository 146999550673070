import React, { FC } from 'react';
import styles from './text-area.module.scss';
import tStyles from '../../assets/css/text.module.scss';

interface TextAreaProps {
    id?: string,
    label?: string,
    value: string,
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>,
    placeholder?: string,
    className?: string,
    errorMsg?: string,
    disabled?: boolean,
    rows: number,
    required?: boolean,
    maxLength?: number
}

export const TextArea: FC<TextAreaProps> = ({
    id, label, value, onChange, placeholder, className, disabled, errorMsg, rows, required, maxLength
}) => {

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange && onChange(event);
    };

    const containerClassName = `${styles.container} ${className}`;
    return (
        <div className={containerClassName}>
            <div className={styles.labelContainer}>
                <label className={tStyles.bigText}>
                    {label} {required && <span>*</span>}
                </label>
            </div>
            <textarea
                id={id}
                className={styles.textArea}
                onChange={handleChange}
                placeholder={placeholder}
                value={value}
                rows={rows}
                disabled={disabled}
                maxLength={maxLength}
            />
            {errorMsg &&
                <div className={styles.error}>
                    {errorMsg}
                </div>
            }
        </div>
    );
};