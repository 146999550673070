import React from 'react';
import { Suspense } from 'react';
import {Provider} from 'react-redux';
// import {initOptions, keycloakClient} from './keycloak/Keycloak';
// import {ReactKeycloakProvider} from '@react-keycloak/web';
import store from './store/store';
import PageRouter from './routes/PageRouter';
import './assets/css/globals.scss';

function App() {
    return (
        <Provider store={store}>
            {/*//     <ReactKeycloakProvider*/}
            {/*//         authClient={keycloakClient}*/}
            {/*//         initOptions={initOptions}*/}
            {/*//     >*/}
            <Suspense fallback={<div></div>}>
                <PageRouter />
            </Suspense>
            {/*// </ReactKeycloakProvider>*/}
        </Provider>
    );
}

export default App;
