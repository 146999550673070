import Header from '../../components/header/Header';
import styles from './home.module.scss';
import QrCodeContainer from './QrCodeContainer';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import {useEffect} from 'react';
import i18n from '../../translations/i18n';

const Home = () => {

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove('_ga');
        Cookies.remove('_gat');
        Cookies.remove('_gid');
        Cookies.remove('CookieConsent');
    };

    const handleAcceptCookie = () => {
        ReactGA.initialize('G-4RY20D1D0S');
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === 'true') {
            handleAcceptCookie();
        }
    }, []);

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.bodyElement}>
                <QrCodeContainer />
                {/*<Banner />*/}
                <div className={styles.faq}>
                    <div className={styles.title}>{i18n.t('qrCode.faq')}</div>
                    <div className={styles.desc}>{i18n.t('qrCode.desc')}</div>
                </div>
            </div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            <CookieConsent
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
                buttonStyle={{
                    backgroundColor: '#4CAF50',
                    color: '#fff',
                    fontSize: '14px',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    margin: '10px',
                }}
                declineButtonStyle={{
                    backgroundColor: '#f44336',
                    color: '#fff',
                    fontSize: '14px',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    margin: '10px',
                }}
                style={{
                    width: 'auto',
                    backgroundColor: '#2b2b2b',
                    color: '#fff',
                    fontSize: '14px',
                    textAlign: 'center',
                    padding: '20px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                    right: 0
                }}
                contentStyle={{
                    flex: 1,
                    marginBottom: '15px',
                }}
            >
                {i18n.t('cookie.text')}
            </CookieConsent>
        </div>
    );
};

export default Home;