import styles from './header.module.scss';
import tStyles from '../../assets/css/text.module.scss';
import i18n from '../../translations/i18n';

const Header = () => {


    return (
        <div className={styles.container}>
            <div>
                {/*todo logo*/}
            </div>
            <div className={styles.titleWrapper}>
                <div className={tStyles.title}>
                    {i18n.t('header.title')}
                </div>
            </div>
            {/*<div>*/}
            {/*    <CustomButton*/}
            {/*        text = {i18n.t('common.signup')}*/}
            {/*        variant={'contained'}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};

export default Header;