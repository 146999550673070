
const modifySvgColor = (svgContent: string, color: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');

    const paths = doc.querySelectorAll('path');
    paths.forEach(path => {
        path.setAttribute('fill', color);
    });

    const serializedSvg = new XMLSerializer().serializeToString(doc.documentElement);
    return `data:image/svg+xml;base64,${btoa(serializedSvg)}`;
};

export const getQrCodeEyeImage = (id: string, eyeImage: string, width: string, height: string, xCordinate: string, yCordinate: string, color: string) => {
    const svgContent = atob(eyeImage.split(',')[1]);
    const coloredQrEyeMarker = modifySvgColor(svgContent, color);

    const img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    img.setAttribute('x', xCordinate);
    img.setAttribute('y', yCordinate);
    img.setAttribute('width', width);
    img.setAttribute('height', height);
    img.setAttribute('href', coloredQrEyeMarker);
    img.setAttribute('id', id);

    return img;
};

const getXCordinate = (el: SVGRectElement | SVGImageElement) => el.getAttribute('x') ?? '0';
const getYCordinate = (el: SVGRectElement | SVGImageElement) => el.getAttribute('y') ?? '0';
const getWidth = (el: SVGRectElement | SVGImageElement) => el.getAttribute('width') ?? '0';
const getHeight = (el: SVGRectElement | SVGImageElement) => el.getAttribute('height') ?? '0';

export const replaceEyesWithImages = (svg: SVGSVGElement, qrEye: string, eyeBorderColor: string) => {
    const rect00 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-0-0\')"]') as SVGRectElement;
    const rect01 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-0-1\')"]') as SVGRectElement;
    const rect02 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-1-0\')"]') as SVGRectElement;

    if (rect00) {
        const customImage00 = getQrCodeEyeImage('eye-00', qrEye, getWidth(rect00), getHeight(rect00), getXCordinate(rect00), getYCordinate(rect00), eyeBorderColor);
        rect00.parentNode?.replaceChild(customImage00, rect00);
    }

    if (rect01) {
        const customImage01 = getQrCodeEyeImage('eye-01', qrEye, getWidth(rect01), getHeight(rect01), getXCordinate(rect01), getYCordinate(rect01), eyeBorderColor);
        rect01.parentNode?.replaceChild(customImage01, rect01);
    }

    if (rect02) {
        const customImage02 = getQrCodeEyeImage('eye-02', qrEye, getWidth(rect02), getHeight(rect02), getXCordinate(rect02), getYCordinate(rect02), eyeBorderColor);
        rect02.parentNode?.replaceChild(customImage02, rect02);
    }
};

export const addEyeMarkerImages = (svg: SVGSVGElement, qrEyeMarker: string, eyeMarkerColor: string) => {
    const addMarkerToEye = (eyeId: string, markerId: string, eyeMarkerColor: string) => {
        const img = svg.querySelector(`#${eyeId}`) as SVGImageElement;
        if (!img) return;

        const eyeWidth = Number(getWidth(img));
        const eyeHeight =  Number(getHeight(img));
        const eyeX =  Number(getXCordinate(img));
        const eyeY =  Number(getYCordinate(img));

        const markerWidth = eyeWidth / 2;
        const markerHeight = eyeHeight / 2;
        const markerX = eyeX + (eyeWidth - markerWidth) / 2;
        const markerY = eyeY + (eyeHeight - markerHeight) / 2;

        const markerImage = getQrCodeEyeImage(markerId, qrEyeMarker, markerWidth.toString(), markerHeight.toString(), markerX.toString(), markerY.toString(), eyeMarkerColor);
        svg.appendChild(markerImage);
    };

    addMarkerToEye('eye-00', 'marker-00', eyeMarkerColor);
    addMarkerToEye('eye-01', 'marker-01', eyeMarkerColor);
    addMarkerToEye('eye-02', 'marker-02', eyeMarkerColor);
};