import {Dispatch, FC, SetStateAction} from 'react';
import styles from './shape-box.module.scss';
import {ReactComponent as ClassyIcon} from '../../../assets/icons/dots/classy.svg';
import {ReactComponent as ClassyRoundedIcon} from '../../../assets/icons/dots/classy-rounded.svg';
import {ReactComponent as DotsIcon} from '../../../assets/icons/dots/dots.svg';
import {ReactComponent as SquareIcon} from '../../../assets/icons/dots/square.svg';
import {ReactComponent as ExtraRoundedIcon} from '../../../assets/icons/dots/extra-rounded.svg';
import {ReactComponent as RoundedIcon} from '../../../assets/icons/dots/rounded.svg';
import {
    QR_CODE_DOT_TYPE_CLASSY,
    QR_CODE_DOT_TYPE_CLASSY_ROUNDED,
    QR_CODE_DOT_TYPE_DOTS,
    QR_CODE_DOT_TYPE_EXTRA_ROUNDED,
    QR_CODE_DOT_TYPE_ROUNDED,
    QR_CODE_DOT_TYPE_SQUARE,
    QrCodeDotType
} from '../../../types/qrcode';


interface ShapeBoxProps {
    setShape: Dispatch<SetStateAction<QrCodeDotType>>;
}

const ShapeBox: FC<ShapeBoxProps> = ({
    setShape
}) => {

    return (
        <div className={styles.logoContainer}>
            <div className={styles.logoWrapper}>
                <DotsIcon className={styles.logo} onClick={() => setShape(QR_CODE_DOT_TYPE_DOTS)}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setShape(QR_CODE_DOT_TYPE_SQUARE)}>
                <SquareIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setShape(QR_CODE_DOT_TYPE_ROUNDED)}>
                <RoundedIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setShape(QR_CODE_DOT_TYPE_EXTRA_ROUNDED)}>
                <ExtraRoundedIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setShape(QR_CODE_DOT_TYPE_CLASSY)}>
                <ClassyIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setShape(QR_CODE_DOT_TYPE_CLASSY_ROUNDED)}>
                <ClassyRoundedIcon className={styles.logo}/>
            </div>
        </div>
    );
};

export default ShapeBox;