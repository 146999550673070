export const SIZE_300 = 300;
export const SIZE_600 = 600;
export const SIZE_900 = 900;
export const SIZE_1200 = 1200;
export const SIZE_1500 = 1500;

export type QRCodeFilterSizes =
    typeof SIZE_300 |
    typeof SIZE_600 |
    typeof SIZE_900 |
    typeof SIZE_1200 |
    typeof SIZE_1500;


export const QR_CODE_LOGO_SIZE_SMALL = 0.1;
export const QR_CODE_LOGO_SIZE_MEDIUM = 0.2;
export const QR_CODE_LOGO_SIZE_BIG = 0.3;

export type QRCodeLogoSize =
    typeof QR_CODE_LOGO_SIZE_SMALL |
    typeof QR_CODE_LOGO_SIZE_MEDIUM |
    typeof QR_CODE_LOGO_SIZE_BIG;


export const QR_CODE_OPTION_LINK = 'LINK';
export const QR_CODE_OPTION_SMS = 'SMS';
export const QR_CODE_OPTION_PHONE = 'PHONE';
export const QR_CODE_OPTION_TEXT = 'TEXT';
export const QR_CODE_OPTION_EMAIL = 'EMAIL';
export const QR_CODE_OPTION_WIFI = 'WIFI';

export type QRCodeOptions = 
    typeof QR_CODE_OPTION_LINK |
    typeof QR_CODE_OPTION_SMS |
    typeof QR_CODE_OPTION_PHONE |
    typeof QR_CODE_OPTION_TEXT |
    typeof QR_CODE_OPTION_EMAIL |
    typeof QR_CODE_OPTION_WIFI;

export const PNG = 'png';
export const SVG = 'svg';

export type QrCodeImageType =
    typeof PNG |
    typeof SVG;

export const QR_CODE_DOT_TYPE_DOTS = 'dots';
export const QR_CODE_DOT_TYPE_ROUNDED = 'rounded';
export const QR_CODE_DOT_TYPE_CLASSY = 'classy';
export const QR_CODE_DOT_TYPE_CLASSY_ROUNDED = 'classy-rounded';
export const QR_CODE_DOT_TYPE_SQUARE = 'square';
export const QR_CODE_DOT_TYPE_EXTRA_ROUNDED = 'extra-rounded';

export type QrCodeDotType =
    typeof QR_CODE_DOT_TYPE_DOTS |
    typeof QR_CODE_DOT_TYPE_ROUNDED |
    typeof QR_CODE_DOT_TYPE_CLASSY |
    typeof QR_CODE_DOT_TYPE_CLASSY_ROUNDED |
    typeof QR_CODE_DOT_TYPE_SQUARE |
    typeof QR_CODE_DOT_TYPE_EXTRA_ROUNDED;
