import {FC} from 'react';
import styles from './generate-qrcode-button.module.scss';
import Button, {ButtonProps} from '@mui/material/Button';
import {ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
//import {ReactComponent as ReloadIcon } from "../../assets/icons/reload.svg";
import i18n from '../../translations/i18n';
import {styled} from '@mui/material';
import {PNG, QrCodeImageType, SVG} from '../../types/qrcode';

export interface GenerateQrCodeButtonProps {
    downloadDisabled: boolean,
    downloadQrCode: (imageType: QrCodeImageType, download: boolean) => void
}

const GenerateQrCodeButton: FC<GenerateQrCodeButtonProps> = ({
    downloadDisabled, downloadQrCode
}) => {
    // const generateQrCode = () => {
    //     setDownloadDisabled(false);
    // };

    const downloadQrCodePNG = () => downloadQrCode(PNG, true);
    const downloadQrCodeSVG = () => downloadQrCode(SVG, true);

    return (
        <div className={styles.container}>
            {/*<StyledButton*/}
            {/*    variant="contained"*/}
            {/*    color="success"*/}
            {/*    startIcon={<ReloadIcon className={styles.icon} />}*/}
            {/*    onClick={generateQrCode}*/}
            {/*>*/}
            {/*    {i18n.t('common.createQrCode')}*/}
            {/*</StyledButton>*/}
            <StyledButton
                variant="contained"
                color="success"
                startIcon={<DownloadIcon className={styles.icon} />}
                onClick={downloadQrCodePNG}
                disabled={downloadDisabled}
            >
                {i18n.t('common.download')} PNG
            </StyledButton>
            <StyledButton
                variant="contained"
                color="success"
                startIcon={<DownloadIcon className={styles.icon} />}
                onClick={downloadQrCodeSVG}
                disabled={downloadDisabled}
            >
                {i18n.t('common.download')} SVG
            </StyledButton>
        </div>
    );
};

export default GenerateQrCodeButton;


const StyledButton = styled(Button)<ButtonProps>(() => ({
    color: '#FFFFFF',
    backgroundColor: '#29A3E0',
    width: '240px',
    '&:hover': {
        backgroundColor: '#097CB6',
    },
}));