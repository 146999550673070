import {FC, ReactNode, useState} from 'react';
import styles from './dropdown-box.module.scss';
import tStyles from '../../assets/css/text.module.scss';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';

interface DropdownBoxProps {
    text: string;
    dropdownElement: ReactNode;
}

const DropdownBox: FC<DropdownBoxProps> = ({
    text, dropdownElement
}) => {
    const [open, setOpen] = useState<boolean>(false);


    return (
        <div>
            <div className={styles.container} onClick={() => setOpen(prevState => !prevState)}>
                <div className={tStyles.subtitle}>
                    {text}
                </div>
                <div className={styles.iconWrapper}>
                    <ArrowIcon className={open ? styles.iconUp : styles.iconDown}/>
                </div>
            </div>
            {open &&
                <div className={styles.box}>
                    {dropdownElement}
                </div>
            }
        </div>
    )
    ;

};

export default DropdownBox;