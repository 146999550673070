import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
//import { Protected } from './Protected';
import { routes } from '../constants/routes';
// import useLoginController from './useLoginController';
import { getUrl } from '../utils/config';
import Home from '../pages/home/Home';
// import Dashboard from '../pages/dashboard/Dashboard';
// import {Protected} from './Protected';

const PageRouter = () => {
    const router = createBrowserRouter([
        {
            path: getUrl(routes.home),
            element: <Home />,
        },
        // {
        //     path: getUrl(routes.dashboard),
        //     element: <Protected component={<Dashboard />} />,
        // }
    ]);

    // const { initialized } = useLoginController();
    //
    // if (!initialized) {
    //     return <div>Loading...</div>;
    // }

    return <RouterProvider router={router} />;
};

export default PageRouter;
