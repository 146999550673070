import React, { FC } from 'react';
import styles from './input.module.scss';
import tStyles from '../../assets/css/text.module.scss';

export type InputType = 'text' | 'email' | 'password' | 'file';

export interface InputMessage {
    error: boolean,
    msg: string
}

interface InputProps {
    id?: string;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    type?: InputType;
    placeholder?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    msg?: InputMessage;
    required?: boolean;
    errorMsg?: string;
}

export const Input: FC<InputProps> = ({
    id, label, value, type, placeholder, onChange, className, disabled, required, errorMsg
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event);
    };

    return (
        <div className={`${styles.container} ${className}`}>
            <label className={tStyles.bigText}>
                {label}{required && <span>*</span>}
            </label>
            <input
                id={id}
                className={styles.input}
                onChange={handleChange}
                value={value || ''}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />
            {errorMsg &&
                <div className={styles.error}>
                    {errorMsg}
                </div>
            }
        </div>
    );
};