import QRCodeStyling from 'qr-code-styling';
import {QrCodeDotType, QRCodeFilterSizes, QRCodeLogoSize} from '../../types/qrcode';

export const getQrCodePrettier = (
    qrLogo: string, data: string, qrCodeSize: QRCodeFilterSizes, qrLogoSize: QRCodeLogoSize,
    hideBackgroundDots: boolean, bgColor: string, dotsColor: string, shape: QrCodeDotType
) => {

    return new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        shape: 'square',
        type: 'svg',
        data: data,
        image: qrLogo,
        qrOptions: {
            errorCorrectionLevel: 'H',
        },
        dotsOptions: {
            color: dotsColor,
            type: shape
        },
        backgroundOptions: {
            color: bgColor,
        },
        imageOptions: {
            margin: 8,
            imageSize: qrLogoSize,
            hideBackgroundDots: hideBackgroundDots
        },
        cornersSquareOptions: {
            type: 'extra-rounded',
            color: 'red',
        },
    });
};