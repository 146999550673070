import styles from './qrcode-creator-box.module.scss';
import DropdownBox from '../dropdown/DropdownBox';
import i18n from '../../translations/i18n';
import {downloadFile} from '../../utils/download';
import {getQrCodePrettier} from '../qrCode/QrCodePrettier';
import React, {FC, useEffect, useRef, useState} from 'react';
import {addEyeMarkerImages, replaceEyesWithImages} from './qrCodeCreatorUtils';
import LogoBox from './logoBox/LogoBox';
import {isEmpty, notBlank} from '../../utils/common';
import {
    PNG,
    QR_CODE_DOT_TYPE_DOTS,
    QR_CODE_LOGO_SIZE_MEDIUM,
    QrCodeDotType,
    QrCodeImageType,
    QRCodeLogoSize,
    SVG
} from '../../types/qrcode';
import ColorBox from './colorBox/ColorBox';
import EyeBox from './eyeBox/EyeBox';
import {rectangle2Base64Image} from '../../assets/icons/base64/eyes/rectangle2';
import {rectangle2MarkerBase64Image} from '../../assets/icons/base64/eyes/rectangle2-marker';
import GenerateQrCodeButton from '../qrCodeButton/GenerateQrCodeButton';
import {Input} from '../input/Input';
import ShapeBox from './shapeBox/ShapeBox';

//export type QRCodeFilterSizes = 300 | 600 | 1200;
//const qrCodeSizeItems: string[] = ['300', '600', '1200'];
const qrCodeSize = 300;

interface QrCodeCreatorBoxProps {
    qrCodeData: string
}

const QrCodeCreatorBox: FC<QrCodeCreatorBoxProps> = ({
    qrCodeData
}) => {
    const [qrCodeName, setQrCodeName] = useState<string>('');
    const [qrLogo, setQrLogo] = useState<string>('');
    const [qrLogoSize, setQrLogoSize] = useState<QRCodeLogoSize>(QR_CODE_LOGO_SIZE_MEDIUM);
    const [qrEye, setQrEye] = useState<string>(rectangle2Base64Image);
    const [qrEyeMarker, setQrEyeMarker] = useState<string>(rectangle2MarkerBase64Image);
    const [hideBackgroundDots, setHideBackgroundDots] = useState<boolean>(true);
    const [bgColor, setBgColor] = useState<string>('#FFFFFF');
    const [dotsColor, setDotsColor] = useState<string>('#231F20');
    const [eyeBorderColor, setEyeBorderColor] = useState<string>('#231F20');
    const [eyeMarkerColor, setEyeMarkerColor] = useState<string>('#231F20');
    const [shape, setShape] = useState<QrCodeDotType>(QR_CODE_DOT_TYPE_DOTS);
    //const frameRef = useRef<SVGSVGElement>(null);
    //const [frameBox, setFrameBox] = useState<ReactElement>(<ScanMe1Icon style={{width: '340px', marginTop: '-14px'}}/>);

    const canvasRef = useRef<HTMLDivElement>(null);


    const handleChangeQrCodeName = (event: React.ChangeEvent<HTMLInputElement>) => setQrCodeName(event.target.value);

    const downloadSVG = (svgElement: Element) => {
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        const fileName = isEmpty(qrCodeName) ? i18n.t('common.myQrCode') : qrCodeName.replace(/\s+/g, '');
        downloadFile(url, `${fileName}.svg`);
    };

    const downloadPNG = (svgElement: Element) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
            const svgData = new XMLSerializer().serializeToString(svgElement);
            const img = new Image();
            const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
            const url = URL.createObjectURL(svgBlob);

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);

                URL.revokeObjectURL(url);

                const pngDataUrl = canvas.toDataURL('image/png');

                const fileName = isEmpty(qrCodeName) ? i18n.t('common.myQrCode') : qrCodeName.replace(/\s+/g, '');
                downloadFile(pngDataUrl, `${fileName}.png`);
            };

            img.src = url;
        }
    };

    const downloadQrCodePrettier = async (imageType: QrCodeImageType, download: boolean = true) => {
        const qrCodeImage = getQrCodePrettier(qrLogo, qrCodeData, qrCodeSize, qrLogoSize, hideBackgroundDots, bgColor, dotsColor, shape);

        if (!canvasRef.current) return;

        //qrCodeImage.append(canvasRef.current);
        return await new Promise<SVGSVGElement | undefined>((resolve, reject) => {
            qrCodeImage.getRawData(SVG)
                .then(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const parser = new DOMParser();
                        const svgDoc = parser.parseFromString(reader.result as string, 'image/svg+xml');
                        const svgElement = svgDoc.querySelector('svg');

                        if (svgElement) {
                            notBlank(qrEye) && replaceEyesWithImages(svgElement, qrEye, eyeBorderColor);
                            addEyeMarkerImages(svgElement, qrEyeMarker, eyeMarkerColor);
                            if (canvasRef.current) {
                                //canvasRef.current.innerHTML = '';
                                //canvasRef.current.appendChild(svgElement);
                                if (imageType === PNG) {
                                    download && downloadPNG(svgElement);
                                } else {
                                    download && downloadSVG(svgElement);
                                }
                            }

                            resolve(svgElement);
                        }
                    };
                    blob && reader.readAsText(blob);
                }).catch(error => {
                    reject(error);
                });
        });
    };

    useEffect(() => {
        const qrCodeDataShow = isEmpty(qrCodeData) ? 'test' : qrCodeData;

        const qrCodeImage = getQrCodePrettier(qrLogo, qrCodeDataShow, qrCodeSize, qrLogoSize, hideBackgroundDots, bgColor, dotsColor, shape);
        qrCodeImage.getRawData(SVG)
            .then(blob => {
                const reader = new FileReader();
                reader.onload = () => {
                    const parser = new DOMParser();
                    const svgDoc = parser.parseFromString(reader.result as string, 'image/svg+xml');
                    const svgElement = svgDoc.querySelector('svg');
                    if (svgElement) {
                        notBlank(qrEye) && replaceEyesWithImages(svgElement, qrEye, eyeBorderColor);
                        addEyeMarkerImages(svgElement, qrEyeMarker, eyeMarkerColor);
                        if (canvasRef.current) {
                            canvasRef.current.innerHTML = '';
                            canvasRef.current.appendChild(svgElement);
                        }
                    }
                };
                blob && reader.readAsText(blob);
            });
    }, [qrCodeData, qrLogo, qrEye, qrLogoSize, hideBackgroundDots, bgColor, dotsColor, qrEyeMarker, eyeBorderColor, eyeMarkerColor, shape]);

    return (
        <div className={styles.container}>
            <div className={styles.qrcodeContainer}>
                {/*{frameBox}*/}
                <div ref={canvasRef} className={`${styles.qrcodeWrapper}`}></div>
            </div>
            <Input
                value={qrCodeName}
                onChange={handleChangeQrCodeName}
                placeholder={i18n.t('common.enterQrCodeName')}
            />
            <DropdownBox
                text={i18n.t('common.logo')}
                dropdownElement={
                    <LogoBox
                        setQrLogo={setQrLogo}
                        setQrLogoSize={setQrLogoSize}
                        setHideBackgroundDots={setHideBackgroundDots}
                    />
                }
            />
            <DropdownBox
                text={i18n.t('common.cornerEyes')}
                dropdownElement={
                    <EyeBox
                        setQrEye={setQrEye}
                        setQrEyeMarker={setQrEyeMarker}
                    />
                }
            />
            <DropdownBox
                text={i18n.t('common.shapes')}
                dropdownElement={
                    <ShapeBox setShape={setShape} />
                }
            />
            <DropdownBox
                text={i18n.t('common.colors')}
                dropdownElement={
                    <ColorBox
                        bgColor={bgColor}
                        setBgColor={setBgColor}
                        dotsColor={dotsColor}
                        setDotsColor={setDotsColor}
                        eyeBorderColor={eyeBorderColor}
                        setEyeBorderColor={setEyeBorderColor}
                        eyeMarkerColor={eyeMarkerColor}
                        setEyeMarkerColor={setEyeMarkerColor}
                    />
                }
            />
            {/*<DropdownBox*/}
            {/*    text={i18n.t('common.frames')}*/}
            {/*    dropdownElement={*/}
            {/*        <FrameBox setFrameBox={setFrameBox} frameRef={frameRef}/>*/}
            {/*    }*/}
            {/*/>*/}
            <GenerateQrCodeButton
                downloadDisabled={isEmpty(qrCodeData)}
                downloadQrCode={downloadQrCodePrettier}
            />
        </div>
    );
};

export default QrCodeCreatorBox;