import {ChangeEvent, Dispatch, FC, SetStateAction} from 'react';
import {Switch} from '@mui/material';
import styles from './logo-box.module.scss';
import i18n from '../../../translations/i18n';
import {ReactComponent as LogoExample1Icon} from '../../../assets/icons/logo-example1.svg';
import {ReactComponent as BitcoinIcon} from '../../../assets/icons/bitcoin.svg';
import {ReactComponent as MessageIcon} from '../../../assets/icons/message.svg';
import {ReactComponent as MapIcon} from '../../../assets/icons/map.svg';
import {ReactComponent as IdCardIcon} from '../../../assets/icons/id-card.svg';
import {ReactComponent as WifiIcon} from '../../../assets/icons/wifi.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import {ReactComponent as UploadIcon} from '../../../assets/icons/upload.svg';
import {logoExample1Base64Image} from '../../../assets/icons/base64/logo-example1';
import {mapBase64Image} from '../../../assets/icons/base64/map';
import {messageBase64Image} from '../../../assets/icons/base64/message';
import {wifiBase64Image} from '../../../assets/icons/base64/wifi';
import {idCardBase64Image} from '../../../assets/icons/base64/id-card';
import {bitcoinBase64Image} from '../../../assets/icons/base64/bitcoin';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
    QR_CODE_LOGO_SIZE_BIG,
    QR_CODE_LOGO_SIZE_MEDIUM,
    QR_CODE_LOGO_SIZE_SMALL,
    QRCodeLogoSize
} from '../../../types/qrcode';


interface LogoBoxProps {
    setQrLogo: Dispatch<SetStateAction<string>>;
    setQrLogoSize: Dispatch<SetStateAction<QRCodeLogoSize>>;
    setHideBackgroundDots: Dispatch<SetStateAction<boolean>>;
}

const LogoBox: FC<LogoBoxProps> = ({
    setQrLogo, setQrLogoSize, setHideBackgroundDots
}) => {

    const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQrLogoSize(Number(event.target.value) as QRCodeLogoSize);
    };

    const handleBackgroundDots = () => setHideBackgroundDots(prevState => !prevState);

    const handleLogoUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setQrLogo(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className={styles.logoContainer}>
            <div className={styles.logoWrapper}>
                <CloseIcon className={styles.logo} onClick={() => setQrLogo('')}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setQrLogo(logoExample1Base64Image)}>
                <LogoExample1Icon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setQrLogo(bitcoinBase64Image)}>
                <BitcoinIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setQrLogo(idCardBase64Image)}>
                <IdCardIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setQrLogo(wifiBase64Image)}>
                <WifiIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setQrLogo(messageBase64Image)}>
                <MessageIcon className={styles.logo}/>
            </div>
            <div className={styles.logoWrapper} onClick={() => setQrLogo(mapBase64Image)}>
                <MapIcon className={styles.logo}/>
            </div>
            <div className={styles.upload}>
                <input className={styles.upload} type="file" accept="image/*" onChange={handleLogoUpload} />
                <UploadIcon className={styles.uploadLogo}/>
                {i18n.t('common.uploadLogo')}
            </div>
            <FormControl className={styles.form}>
                <RadioGroup
                    className={styles.radioGroup}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleSizeChange}
                    defaultValue={QR_CODE_LOGO_SIZE_MEDIUM}
                >
                    <FormControlLabel
                        className={styles.checkboxWrapper}
                        value={QR_CODE_LOGO_SIZE_SMALL}
                        control={<Radio />}
                        label={i18n.t('common.small')}
                    />
                    <FormControlLabel
                        className={styles.checkboxWrapper}
                        value={QR_CODE_LOGO_SIZE_MEDIUM}
                        control={<Radio />}
                        label={i18n.t('common.medium')}
                    />
                    <FormControlLabel
                        className={styles.checkboxWrapper}
                        value={QR_CODE_LOGO_SIZE_BIG}
                        control={<Radio />}
                        label={i18n.t('common.big')}
                    />
                </RadioGroup>
                <div className={styles.normalText}>
                    {i18n.t('logo.removeDotsLogo')}
                    <Switch defaultChecked={true} onChange={handleBackgroundDots}/>
                </div>
            </FormControl>
        </div>
    );
};

export default LogoBox;