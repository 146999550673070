// timeouts
export const ALERT_TIMEOUT = 5000;
export const KEYCLOAK_TOKEN_SESSION_TIMEOUT = 6000;


// alerts
export const SUCCESS = 'success';
export const ERROR = 'error';

export const WIFI_ENCYRPT_TYPE_WEP = 'WEP';
export const WIFI_ENCYRPT_TYPE_WPA = 'WPA/WPA2';

export const WIFI_ENCRYPT_ITEMS = [WIFI_ENCYRPT_TYPE_WPA, WIFI_ENCYRPT_TYPE_WEP];